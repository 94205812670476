import React from 'react'
import {
  DefaultEntityFilters,
  EntityAutocompletePicker
} from '@backstage/plugin-catalog-react'
import { EntityServiceCategoryFilter } from './EntityComponentsFilter'

type CombinedFilters = DefaultEntityFilters & {
  serviceCategory: EntityServiceCategoryFilter
}

export type EntityServiceCategoryPickerProps = {
  showCounts?: boolean
}

export function EntityServiceCategoryPicker(props: EntityServiceCategoryPickerProps) {
  return (
    <EntityAutocompletePicker<CombinedFilters>
      label="Service Category"
      name="serviceCategory"
      path="metadata.annotations.sennder.com/service-category"
      Filter={EntityServiceCategoryFilter}
      showCounts={props.showCounts}
    />
  )
}
