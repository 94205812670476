import { EntityFilter } from '@backstage/plugin-catalog-react'
import { Entity } from '@backstage/catalog-model'

export class EntityExecutionModeFilter implements EntityFilter {
  constructor(readonly values: string[]) {}

  filterEntity(entity: Entity): boolean {
    const emRef = entity.metadata.annotations?.['sennder.com/execution-mode'] as
      | string
      | undefined
    return emRef ? this.values?.includes(emRef) : false
  }

  toQueryValue(): string[] {
    return this.values
  }
}

export class EntityServiceCategoryFilter implements EntityFilter {
  constructor(readonly values: string[]) {}

  filterEntity(entity: Entity): boolean {
    const scRef = entity.metadata.annotations?.['sennder.com/service-category'] as
      | string
      | undefined
    return scRef ? this.values?.includes(scRef) : false
  }

  toQueryValue(): string[] {
    return this.values
  }
}
