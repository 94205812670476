import React, { PropsWithChildren, useMemo, useState } from 'react'

import { EntityLayout } from '@backstage/plugin-catalog'
import {
  Entity,
  getCompoundEntityRef,
  stringifyEntityRef
} from '@backstage/catalog-model'
import { EntityBadgesDialog } from '@backstage/plugin-badges'
import { EntityDebugChecksDialog } from '@internal/plugin-tech-insights-overview'
import BadgeIcon from '@material-ui/icons/CallToAction'
import ChecksIcon from '@material-ui/icons/CheckCircleOutline'

import CopyIcon from '@material-ui/icons/FileCopy'
import { useCopyToClipboard } from 'react-use'
import { AlertApi, alertApiRef, useApi } from '@backstage/core-plugin-api'
import { useAsyncEntity } from '@backstage/plugin-catalog-react'

export function EntityLayoutWrapper(props: PropsWithChildren<{}>) {
  const [badgesDialogOpen, setBadgesDialogOpen] = useState(false)
  const [debugChecksDialogOpen, setDebugChecksDialogOpen] = useState(false)
  const [, copyToClipboard] = useCopyToClipboard()
  const { entity } = useAsyncEntity()
  const _copyToClipboard = (alertApi: AlertApi, entity?: Entity) => {
    if (typeof entity === 'undefined') {
      return
    }
    const entityRef = stringifyEntityRef(getCompoundEntityRef(entity))
    copyToClipboard(entityRef)
    alertApi.post({
      severity: 'success',
      message: `Entity reference ${entityRef} has been copied to the clipboard`
    })
  }
  const alertApi = useApi(alertApiRef)
  const extraMenuItems = useMemo(() => {
    return [
      {
        title: 'Copy entity reference',
        Icon: CopyIcon,
        onClick: () => _copyToClipboard(alertApi, entity)
      },
      {
        title: 'View badges',
        Icon: BadgeIcon,
        onClick: () => setBadgesDialogOpen(true)
      },
      {
        title: 'Debug checks',
        Icon: ChecksIcon,
        onClick: () => setDebugChecksDialogOpen(true)
      }
    ]
  }, [])

  return (
    <>
      <EntityLayout UNSTABLE_extraContextMenuItems={extraMenuItems}>
        {props.children}
      </EntityLayout>
      <EntityBadgesDialog
        open={badgesDialogOpen}
        onClose={() => setBadgesDialogOpen(false)}
      />
      <EntityDebugChecksDialog
        open={debugChecksDialogOpen}
        onClose={() => setDebugChecksDialogOpen(false)}
      />
    </>
  )
}
