import { EntityFilter } from '@backstage/plugin-catalog-react'
import { Entity } from '@backstage/catalog-model'

export class EntityDatabaseEngineFilter implements EntityFilter {
  constructor(readonly values: string[]) {}

  filterEntity(entity: Entity): boolean {
    const dbRef = entity.metadata.annotations?.['sennder.com/database-engine'] as
      | string
      | undefined
    return dbRef ? this.values?.includes(dbRef) : false
  }

  toQueryValue(): string[] {
    return this.values
  }
}

export class EntityQueueServiceFilter implements EntityFilter {
  constructor(readonly values: string[]) {}

  filterEntity(entity: Entity): boolean {
    const qsRef = entity.metadata.annotations?.['sennder.com/queue-service'] as
      | string
      | undefined
    return qsRef ? this.values?.includes(qsRef) : false
  }

  toQueryValue(): string[] {
    return this.values
  }
}
