import React from 'react'
import {
  DefaultEntityFilters,
  EntityAutocompletePicker
} from '@backstage/plugin-catalog-react'
import { EntityQueueServiceFilter } from './EntityResourcesFilter'

type CombinedFilters = DefaultEntityFilters & {
  queueService: EntityQueueServiceFilter
}

export type EntityQueueServicePickerProps = {
  showCounts?: boolean
}

export function EntityQueueServicePicker(props: EntityQueueServicePickerProps) {
  return (
    <EntityAutocompletePicker<CombinedFilters>
      label="Queue Service"
      name="queueService"
      path="metadata.annotations.sennder.com/queue-service"
      Filter={EntityQueueServiceFilter}
      showCounts={props.showCounts}
    />
  )
}
