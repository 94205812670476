/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { PropsWithChildren } from 'react'
import { Link, makeStyles } from '@material-ui/core'

import MapIcon from '@material-ui/icons/MyLocation'
import LibraryBooks from '@material-ui/icons/LibraryBooks'
import SchoolIcon from '@material-ui/icons/School'

import CreateComponentIcon from '@material-ui/icons/Add'
import CatalogIcon from '@material-ui/icons/ViewList'
import ApiIcon from '@material-ui/icons/Extension'
import ComponentIcon from '@material-ui/icons/Memory'
import SystemIcon from '@material-ui/icons/Category'
import UserIcon from '@material-ui/icons/Face'
import UnprocessedIcon from '@material-ui/icons/SyncProblem'
import StatsIcon from '@material-ui/icons/Timeline'
import Dora4Icon from '@material-ui/icons/DonutSmall'
import CheckEngineIcon from '../tech-insights/CheckEngineIcon'
import VideogameAsset from '@material-ui/icons/VideogameAsset'

import LogoFull from './LogoFull'
import LogoIcon from './LogoIcon'
import { NavLink } from 'react-router-dom'
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar
} from '@backstage/plugin-user-settings'
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  GroupIcon,
  SidebarSubmenu,
  SidebarSubmenuItem,
  useSidebarOpenState,
  DashboardIcon
} from '@backstage/core-components'
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search'
// OpsGenie icon
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'
import BookmarkIcon from '@material-ui/icons/Bookmark'

import { sortByTitle } from '../../utils'
import bookmarks from '../../references/bookmarks'

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24
  }
})

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles()
  const { isOpen } = useSidebarOpenState()

  return (
    <div className={classes.root}>
      <Link
        component={NavLink}
        to="/"
        underline="none"
        className={classes.link}
        aria-label="Home"
      >
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  )
}

export function Root({ children }: PropsWithChildren<{}>) {
  const inIframe = window && window.self !== window.top
  if (inIframe) {
    // If we're embedding a page (for example, to Azure DevOps),
    // render only the body content, without bells and whistles
    return <>{children}</>
  }

  return (
    <SidebarPage>
      <Sidebar>
        <SidebarLogo />
        <SidebarItem icon={SearchIcon} to="/search" text="Search" />
        <SidebarDivider />
        <SidebarGroup label="Menu" icon={<MenuIcon />}>
          <SidebarItem icon={CatalogIcon} to="catalog" text="Catalog">
            <SidebarSubmenu title="Service Catalog">
              <SidebarSubmenuItem
                title="Systems"
                to="catalog?filters[kind]=system"
                icon={SystemIcon}
              />
              <SidebarSubmenuItem
                title="Components"
                to="catalog?filters[kind]=component"
                icon={ComponentIcon}
              />
              <SidebarSubmenuItem
                title="APIs"
                to="catalog?filters[kind]=api"
                icon={ApiIcon}
              />
              <SidebarDivider />
              <SidebarSubmenuItem
                title="Business Areas"
                to="catalog?filters[kind]=group&filters[type]=business-area"
                icon={DashboardIcon}
              />
              <SidebarSubmenuItem
                title="Pods"
                to="catalog?filters[kind]=group&filters[type]=pod"
                icon={GroupIcon}
              />
              <SidebarSubmenuItem
                title="Individuals"
                to="catalog?filters[kind]=user"
                icon={UserIcon}
              />
              <SidebarDivider />
              <SidebarSubmenuItem
                title="Unprocessed"
                to="/catalog-unprocessed-entities"
                icon={UnprocessedIcon}
              />
              <SidebarDivider />
              <SidebarSubmenuItem
                icon={CreateComponentIcon}
                to="create"
                title="Create Repository"
              />
            </SidebarSubmenu>
          </SidebarItem>

          <SidebarItem
            icon={SchoolIcon}
            to="/docs/engineering-and-data-platform/component/tech-onboarding-docs"
            text="Academy"
          />

          <SidebarItem icon={VideogameAsset} to="/contrib" text="Contrib" />

          <SidebarItem icon={LibraryBooks} to="docs" text="Tech Docs">
            <SidebarSubmenu title="Technical Documentation">
              <SidebarSubmenuItem
                icon={LibraryBooks}
                to="docs"
                title="Components’ Docs"
              />
              <SidebarSubmenuItem
                icon={LibraryBooks}
                to="/docs/engineering-and-data-platform/component/developer-portal"
                title="Portal Docs"
              />
              <SidebarSubmenuItem
                icon={LibraryBooks}
                to="/docs/engineering-excellence/component/1es-documentation"
                title="1ES Docs"
              />
              <SidebarDivider />
              {sortByTitle(bookmarks).map(({ title, href }) => (
                <SidebarSubmenuItem
                  key={href}
                  icon={BookmarkIcon}
                  to={href}
                  title={title}
                />
              ))}
            </SidebarSubmenu>
          </SidebarItem>

          <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radar" />

          <SidebarDivider />
          <SidebarItem icon={Dora4Icon} to="/dora" text="DORA4" />
          <SidebarItem
            icon={CheckEngineIcon}
            to="/tech-insights-overview"
            text="Check Engine"
          />
          <SidebarItem icon={AccessAlarmIcon} to="/opsgenie" text="OpsGenie" />
        </SidebarGroup>
        <SidebarSpace />
        <SidebarDivider />
        <SidebarItem icon={StatsIcon} to="stats" text="Stats" />
        <SidebarGroup label="Settings" icon={<UserSettingsSignInAvatar />} to="/settings">
          <SidebarSettings />
        </SidebarGroup>
      </Sidebar>
      {children}
    </SidebarPage>
  )
}
