import React from 'react'
import {
  DefaultEntityFilters,
  EntityAutocompletePicker
} from '@backstage/plugin-catalog-react'
import { EntityDatabaseEngineFilter } from './EntityResourcesFilter'

type CombinedFilters = DefaultEntityFilters & {
  databaseEngine: EntityDatabaseEngineFilter
}

export type EntityDatabaseEnginePickerProps = {
  showCounts?: boolean
}

export function EntityDatabaseEnginePicker(props: EntityDatabaseEnginePickerProps) {
  return (
    <EntityAutocompletePicker<CombinedFilters>
      label="Database Engine"
      name="databaseEngine"
      path="metadata.annotations.sennder.com/database-engine"
      Filter={EntityDatabaseEngineFilter}
      showCounts={props.showCounts}
    />
  )
}
