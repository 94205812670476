import React from 'react'
import { useEntity } from '@backstage/plugin-catalog-react'
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Box
} from '@material-ui/core'
import TechnicalDetailsField from './EntityFields/TechnicalDetails/TechnicalDetailsField'

const EntityTechnicalDetailsCard = () => {
  const { entity } = useEntity()
  const serviceCategory = entity?.metadata?.annotations?.['sennder.com/service-category']
  const databaseEngine = entity?.metadata?.annotations?.['sennder.com/database-engine']
  const queueService = entity?.metadata?.annotations?.['sennder.com/queue-service']
  const executionMode = entity?.metadata?.annotations?.['sennder.com/execution-mode']
  const areAllFieldsUndefined =
    !serviceCategory && !databaseEngine && !queueService && !executionMode

  const databaseEngineSubAnnotations = {
    postgres: [
      'sennder.com/prod-resource-name',
      'sennder.com/datadog-resource-name',
      'sennder.com/replicas'
    ],
    dynamodb: ['sennder.com/prod-resource-name'],
    redis: ['sennder.com/prod-resource-name', 'sennder.com/datadog-resource-name']
  }
  const serviceCategorySubAnnotations = {
    'rest-api': ['sennder.com/metric-type'],
    'outbox-worker': ['sennder.com/topic'],
    consumer: ['sennder.com/topic', 'sennder.com/consumer-group']
  }
  const fields = [
    {
      spec: serviceCategory,
      title: 'Service Category',
      subAnnotations: serviceCategorySubAnnotations
    },
    { spec: executionMode, title: 'Execution Mode' },
    {
      spec: databaseEngine,
      title: 'Database Engine',
      subAnnotations: databaseEngineSubAnnotations
    },
    { spec: queueService, title: 'Queue Service' }
  ]

  return (
    <Card>
      <CardHeader variant="h6" title="Technical Details" />
      <Divider />
      <CardContent>
        {areAllFieldsUndefined ? (
          <Box>
            <Typography variant="body2">
              Technical information not yet provided
            </Typography>
          </Box>
        ) : (
          <Grid container>
            {fields.map(
              (field, index) =>
                field.spec && (
                  <TechnicalDetailsField
                    key={index}
                    spec={field.spec}
                    title={field.title}
                    entity={entity}
                    subAnnotations={field.subAnnotations}
                  />
                )
            )}
          </Grid>
        )}
      </CardContent>
    </Card>
  )
}

export default EntityTechnicalDetailsCard
