/*
 * sennder custom catalog index.
 *
 * Forked from:
 * https://github.com/backstage/backstage/blob/master/plugins/catalog/src/components/CatalogPage/DefaultCatalogPage.tsx
 *
 * As recommended by:
 * https://backstage.io/docs/features/software-catalog/catalog-customization/
 *
 */

import {
  Content,
  ContentHeader,
  CreateButton,
  PageWithHeader,
  SupportButton
} from '@backstage/core-components'
import { configApiRef, useApi, useRouteRef } from '@backstage/core-plugin-api'
import {
  CatalogFilterLayout,
  EntityLifecyclePicker,
  EntityListProvider,
  EntityProcessingStatusPicker,
  EntityTagPicker,
  EntityTypePicker,
  UserListPicker,
  EntityKindPicker
} from '@backstage/plugin-catalog-react'
import React from 'react'
import { catalogPlugin, DefaultCatalogPageProps } from '@backstage/plugin-catalog'
import { CustomCatalogTable } from './CustomCatalogTable'
import { EntityFailedChecksPicker } from './EntityFailedChecksPicker'
import { EntityPassedChecksPicker } from './EntityPassedChecksPicker'
import { EntityBaPodPicker } from './EntityBaPodPicker'
import { EntityArchivalStatePicker } from './EntityArchivalStatePicker'
import { EntityExecutionModePicker } from './EntityExecutionModePicker'
import { EntityServiceCategoryPicker } from './EntityServiceCategoryPicker'
import { EntityQueueServicePicker } from './EntityQueueServicePicker'
import { EntityDatabaseEnginePicker } from './EntityDatabaseEnginePicker'

export function CustomCatalogIndexPage(props: DefaultCatalogPageProps) {
  const {
    columns,
    actions,
    initiallySelectedFilter = 'owned',
    initialKind = 'component',
    tableOptions = {},
    emptyContent
  } = props
  const orgName =
    useApi(configApiRef).getOptionalString('organization.name') ?? 'Backstage'
  const createComponentLink = useRouteRef(catalogPlugin.externalRoutes.createComponent)

  return (
    <PageWithHeader title={`${orgName} Catalog`} themeId="home">
      <Content>
        <ContentHeader title="">
          <CreateButton
            title="+ Component"
            to={createComponentLink && createComponentLink()}
          />
          <SupportButton>All your software catalog entities</SupportButton>
        </ContentHeader>
        <EntityListProvider>
          <CatalogFilterLayout>
            <CatalogFilterLayout.Filters>
              <EntityKindPicker initialFilter={initialKind} />
              <EntityTypePicker />
              <EntityArchivalStatePicker initialFilter="non-archived" />
              <EntityBaPodPicker />
              <UserListPicker initialFilter={initiallySelectedFilter} />
              <EntityServiceCategoryPicker />
              <EntityExecutionModePicker />
              <EntityDatabaseEnginePicker />
              <EntityQueueServicePicker />
              <EntityFailedChecksPicker />
              <EntityPassedChecksPicker />
              <EntityLifecyclePicker />
              <EntityTagPicker />
              <EntityProcessingStatusPicker />
            </CatalogFilterLayout.Filters>
            <CatalogFilterLayout.Content>
              <CustomCatalogTable
                columns={columns}
                actions={actions}
                tableOptions={tableOptions}
                emptyContent={emptyContent}
              />
            </CatalogFilterLayout.Content>
          </CatalogFilterLayout>
        </EntityListProvider>
      </Content>
    </PageWithHeader>
  )
}
