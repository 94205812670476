import React from 'react'
import { Box, Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Entity } from '@backstage/catalog-model'

const useStyles = makeStyles({
  specTitle: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '0.60rem',
    marginBottom: '0.45rem',
    marginRight: '2rem',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  specValue: {
    fontWeight: 'bold',
    marginBottom: '0.5rem'
  }
})

const TechnicalDetailsField = ({
  spec,
  title,
  entity,
  subAnnotations
}: {
  title: string
  entity: Entity
  spec: string | undefined
  subAnnotations: Record<string, string[]> | undefined
}) => {
  const classes = useStyles()
  const annotations = entity?.metadata?.annotations || {}
  const relevantSubAnnotations = spec && subAnnotations ? subAnnotations[spec] : undefined

  return (
    <Grid item xs={6}>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6" className={classes.specTitle} gutterBottom>
          {title}
        </Typography>

        <Typography variant="body2" className={classes.specValue}>
          {spec || 'N/A'}
        </Typography>

        {relevantSubAnnotations && (
          <Box sx={{ mt: 2 }}>
            {relevantSubAnnotations.map((key: string) => {
              const subAnnotationValue = annotations[key]
              const keyName = key.split('/')[1]

              return subAnnotationValue ? (
                <Grid container alignItems="center" spacing={2} key={key}>
                  <Grid item>
                    <Typography variant="body2" className={classes.specTitle}>
                      {keyName}
                    </Typography>
                    <Typography variant="body2" className={classes.specValue}>
                      {subAnnotationValue}
                    </Typography>
                  </Grid>
                </Grid>
              ) : null
            })}
          </Box>
        )}
      </Box>
    </Grid>
  )
}

export default TechnicalDetailsField
