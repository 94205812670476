import React from 'react'
import { TechInsightsOverviewPage } from '@internal/plugin-tech-insights-overview'
import {
  EntityLifecyclePicker,
  EntityTagPicker,
  EntityTypePicker,
  EntityKindPicker
} from '@backstage/plugin-catalog-react'
import { EntityFailedChecksPicker } from '../catalog-index/EntityFailedChecksPicker'
import { EntityPassedChecksPicker } from '../catalog-index/EntityPassedChecksPicker'
import { EntityBaPodPicker } from '../catalog-index/EntityBaPodPicker'

const filters = (
  <>
    <EntityKindPicker />
    <EntityTypePicker />
    <EntityBaPodPicker />
    <EntityFailedChecksPicker />
    <EntityPassedChecksPicker />
    <EntityLifecyclePicker />
    <EntityTagPicker />
  </>
)

export const techInsightsOverviewPage = (
  <TechInsightsOverviewPage
    title="Check Engine"
    subtitle="Tech Insights Overview"
    filters={filters}
  />
)
