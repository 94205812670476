/*
 *
 * A wrapper around the stock CatalogTable component to provide
 * arguably better defaults
 *
 */

import { TableColumn } from '@backstage/core-components'
import { useEntityList } from '@backstage/plugin-catalog-react'
import React, { useMemo } from 'react'
import {
  CatalogTable,
  CatalogTableProps,
  CatalogTableRow
} from '@backstage/plugin-catalog'
import { Entity } from '@backstage/catalog-model'

import { createComboColumn } from './comboColumn'
import { FetchedEntityRefLinks } from '../EntityRefLink/FetchedEntityRefLinks'

// Shim extraction to avoid touching component implementation
// too much. In case, it has to be updated from upstream code.
const columnFactories = CatalogTable.columns

function createTagsColumn(): TableColumn<CatalogTableRow> {
  return {
    ...columnFactories.createTagsColumn(),
    hidden: true,
    searchable: true
  }
}

function getRelationTitle(entity: Entity) {
  return entity.metadata.title || entity.metadata.name
}

function createSystemColumn(): TableColumn<CatalogTableRow> {
  return {
    title: 'System',
    field: 'resolved.partOfSystemRelationTitle',
    width: 'auto',
    cellStyle: {
      maxWidth: '15%'
    },
    render: ({ resolved }) => (
      <FetchedEntityRefLinks
        entityRefs={resolved.partOfSystemRelations}
        defaultKind="system"
        getTitle={getRelationTitle}
      />
    )
  }
}

function createOwnerColumn(): TableColumn<CatalogTableRow> {
  return {
    title: 'Owner',
    field: 'resolved.ownedByRelationsTitle',
    width: 'auto',
    cellStyle: {
      maxWidth: '15%'
    },
    render: ({ resolved }) => (
      <FetchedEntityRefLinks
        entityRefs={resolved.ownedByRelations}
        defaultKind="group"
        getTitle={getRelationTitle}
      />
    )
  }
}

function createSpecTypeColumn(): TableColumn<CatalogTableRow> {
  return {
    ...columnFactories.createSpecTypeColumn(),
    cellStyle: {
      minWidth: '10em',
      maxWidth: '15em'
    }
  }
}

export function CustomCatalogTable(props: CatalogTableProps) {
  const { columns, actions, tableOptions = {}, emptyContent } = props

  const { entities, filters } = useEntityList()

  const defaultColumns: TableColumn<CatalogTableRow>[] = useMemo(() => {
    return [
      columnFactories.createTitleColumn({ hidden: true }),
      createComboColumn({ defaultKind: filters.kind?.value }),
      ...createEntitySpecificColumns(),
      // FORK: description is already in the combo column
      //columnFactories.createMetadataDescriptionColumn()
      createTagsColumn()
    ]

    function createEntitySpecificColumns(): TableColumn<CatalogTableRow>[] {
      const baseColumns = [
        createSystemColumn(),
        createOwnerColumn(),
        createSpecTypeColumn()
        // FORK: we don’t include the lifecycle column in the overview
        //columnFactories.createSpecLifecycleColumn(),
      ]
      switch (filters.kind?.value) {
        case 'user':
          return []
        case 'domain':
        case 'system':
          return [createOwnerColumn()]
        case 'group':
        case 'template':
          return [createSpecTypeColumn()]
        case 'location':
          return [createSpecTypeColumn(), columnFactories.createSpecTargetsColumn()]
        default:
          return baseColumns
      }
    }
  }, [filters.kind?.value])

  // Magic ahead! Backstage relies on “material-table” (https://github.com/mbrn/material-table)
  // component under the hood. The component has many performance problems and one of them
  // leads to behavior when filtering a 1000-row table down to a few rows (less than 20 which
  // is a page size) completelly freezes browser for 4-5 seconds. The bottleneck is somewhat
  // unclear, but profiler shows the problem is with re-creating, re-mounting, re-forwarding,
  // re-rendering of styled MTableRows React component.
  // A simple kludge to completely drop the existing table and render a new one when entities
  // change is enough to work around the issue.
  const hackyKey = entities.length.toString()

  return (
    <CatalogTable
      key={hackyKey}
      columns={columns ?? defaultColumns}
      actions={actions}
      tableOptions={tableOptions}
      emptyContent={emptyContent}
    />
  )
}
